// Core
import React, { useRef, useState, useEffect } from "react"

// InView detector
import { InView } from "react-intersection-observer"

// import { isMobile } from "react-device-detect"

// Components
import Modal from "../carousel-modal/modal"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitText from "../split-text/split-text"

//SVG
// import Arrow from "./../../../static/images/svg/to-top.inline.svg"
import LargeCircle from "./../../../static/images/svg/circle.inline.svg"
import Circle from "./../../../static/images/svg/button-circle.inline.svg"
import Arrow from "./../../../static/images/svg/arrow.inline.svg"

// Styles
import styles from "./text-modal.module.scss"

const TextModal = props => {
  const { sectionData } = props,
    buttonText = sectionData.textModalButtonText,
    copy = sectionData.textCarouselModalMainCopy.textCarouselModalMainCopy

  const [modalOpen, setModalOpen] = useState(false)

  const modalButton = useRef()

  let buttonTheme

  if (props.buttonTheme) {
    buttonTheme = props.buttonTheme
  }

  useEffect(() => {
    const { current } = modalButton
    // sitewrap = document.querySelector(".site-wrap")

    const toggleModal = () => {
      setModalOpen(!modalOpen)
    }

    if (modalOpen === true || modalOpen === "true") {
      document.body.style.top = `-${window.pageYOffset}px`
      document.body.style.left = 0
      document.body.style.width = `100%`
      document.body.style.position = "fixed"
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ""
      document.body.style.top = ""
      document.body.style.left = ""
      document.body.style.width = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }

    if (!current) return
    current.addEventListener("click", toggleModal)

    return () => {
      if (!current) return
      current.removeEventListener("click", toggleModal)
    }
  }, [modalOpen])

  return (
    <React.Fragment>
      <ScrollSection>
        <section className={styles.textModal} data-modal-open={modalOpen}>
          <div className="container">
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div
                  className={`cols flex flex--center ${styles.cols}`}
                  ref={ref}
                  inview={inView.toString()}
                >
                  <LargeCircle />
                  <div
                    className="col m12 t6"
                    data-scroll
                    data-scroll-speed={0.6}
                    data-scroll-delay={0.6}
                  >
                    <SplitText classString={`copy copy--m ${styles.copy}`}>
                      {copy}
                    </SplitText>
                    <button
                      className={`button ${
                        buttonTheme ? `button--${buttonTheme}` : ``
                      } ${styles.button} ${styles.link}`}
                      ref={modalButton}
                    >
                      <span className={styles.circle}>
                        <Circle />
                        <span className={styles.arrowWrap}>
                          <span className={styles.arrowShape}>
                            <Arrow />
                          </span>
                          <span className={styles.arrowShape}>
                            <Arrow />
                          </span>
                        </span>
                      </span>
                      <span className={styles.buttonText}>
                        <span>{buttonText}</span>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </InView>
          </div>
        </section>
      </ScrollSection>
      <Modal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        data={sectionData}
      />
    </React.Fragment>
  )
}

export default TextModal
