import React, { useEffect, useMemo, useRef, useState } from "react"
import { useCallback } from "react"
import Img from "gatsby-image"

// Component
import JsonText from "../_jsonText"
import SplitText from "../split-text/split-text"

//
import styles from "./index.module.scss"

const Animation = ({
  baseSize = 750,
  progress = 0,
  nodes,
  startAnimationDegree,
}) => {
  console.log("nodes", nodes)

  const config = useMemo(() => {
    const config = {
      strokeWidth: 5,
      emptyColor: "rgba(249, 248, 242, 0.2)",
      primaryColor: "#ED9C78",
      startAngle: 0,
    }

    const runnerUpsPercentage = nodes
      .filter(
        node =>
          // progress * 3.15 + (startAnimationDegree === 45 ? 45 + 1 : 0) >
          progress * 3.15 + 46 >
          node.rotation
      )
      .map(value => value.rotation)

    return {
      ...config,
      radius: (baseSize - config.strokeWidth) / 2,
      // endAngle: progress * 3.15 + startAnimationDegree,
      endAngle: progress * 3.15 + 45,
      runnerUpsPercentage,
      activeNodePercentage: Math.max.apply(null, runnerUpsPercentage),
    }
  }, [baseSize, progress])

  const polarToCartesian = useCallback(
    (centerX, centerY, radius, angleInDegrees) => {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
      }
    },
    []
  )

  const describeArc = useCallback(
    (x, y, radius, startAngle, endAngle) => {
      const start = polarToCartesian(x, y, radius, endAngle)
      const end = polarToCartesian(x, y, radius, startAngle)

      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

      const d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y,
      ].join(" ")

      return d
    },
    [polarToCartesian]
  )

  const getCoordinates = useCallback((radius, angle) => {
    // const radian = (Math.PI * 2 * angle) / 360
    const radian = (angle - 90) * (Math.PI / 180)

    // const coordinates = {
    //   x: Math.sin(radian) * radius + radius,
    //   y: Math.cos(radian) * radius + radius,
    // }
    const coordinates = {
      x: Math.cos(radian) * radius + radius,
      y: Math.sin(radian) * radius + radius,
    }

    return coordinates
  }, [])

  const circleSvg = useMemo(() => {
    return (
      <svg
        width={baseSize}
        height={baseSize}
        viewBox={`0 0 ${baseSize} ${baseSize}`}
      >
        <circle
          cx={baseSize / 2}
          cy={baseSize / 2}
          r={config.radius}
          fill="none"
          stroke={config.emptyColor}
          strokeWidth={`${1}px`}
        />
        <path
          fill="none"
          stroke={config.primaryColor}
          strokeWidth={`${config.strokeWidth}px`}
          d={describeArc(
            baseSize / 2,
            baseSize / 2,
            config.radius,
            config.startAngle,
            config.endAngle
          )}
        />
      </svg>
    )
  }, [config])

  const left = useMemo(() => {
    const left = nodes.filter(node => node.align === "left")

    return (
      <div className={`${styles.content} ${styles.left}`}>
        {left?.map(({ subheading, text, rotation }, index) => {
          const { y } = getCoordinates(config.radius, rotation)
          return (
            <div
              className={`${styles.contentInner} ${
                config.activeNodePercentage === rotation ? styles.active : ""
              }`}
              key={index}
              style={{ top: `${y - 15}px` }}
            >
              <div className={styles.subheading}>
                {subheading && <h3>{subheading}</h3>}
              </div>
              <div className={styles.description}>
                {text &&
                  (text.raw ? (
                    <JsonText content={text} split={true} small={true} />
                  ) : (
                    <SplitText>{text}</SplitText>
                  ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  }, [config])

  const right = useMemo(() => {
    const right = nodes.filter(node => node.align === "right")

    return (
      <div className={`${styles.content}`}>
        {right?.map(({ subheading, text, rotation }, index) => {
          const { y } = getCoordinates(config.radius, rotation)
          return (
            <div
              className={`${styles.contentInner} ${
                config.activeNodePercentage === rotation ? styles.active : ""
              }`}
              key={index}
              style={{ top: `${y - 15}px` }}
            >
              <div className={styles.subheading}>
                {subheading && <h3>{subheading}</h3>}
              </div>
              <div className={styles.description}>
                {text &&
                  (text.raw ? (
                    <JsonText content={text} split={true} small={true} />
                  ) : (
                    <SplitText>{text}</SplitText>
                  ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  }, [config])

  return (
    <div
      className={styles.animationContainer}
      style={{
        width: baseSize,
      }}
    >
      {left}
      <div
        className={styles.circleContainer}
        style={{
          height: baseSize,
          width: baseSize,
        }}
      >
        {circleSvg}
        {nodes?.map(({ image, rotation }) => {
          const { x, y } = getCoordinates(config.radius, rotation)

          return (
            <div className={styles.centerSection}>
              <div
                className={`${styles.node} ${
                  config.activeNodePercentage === rotation
                    ? styles.active
                    : config.runnerUpsPercentage.find(
                        value => value === rotation
                      )
                    ? styles.runnerUp
                    : ""
                }`}
                style={{
                  top: `${y}px`,
                  left: `${x}px`,
                }}
              >
                <div className={styles.point}></div>
              </div>
              <div className={styles.innerCircle}>
                <div className={styles.circle}>
                  <div
                    className={`${styles.imageWrapper} ${
                      config.activeNodePercentage === rotation
                        ? styles.active
                        : ""
                    }`}
                  >
                    {image && (
                      <Img fluid={image.fluid} alt={image.description} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      {right}
    </div>
  )
}

export default Animation
