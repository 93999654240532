import React, { useEffect, useMemo, useRef, useState } from "react"
import { useCallback } from "react"
import Img from "gatsby-image"

// Component
import JsonText from "../_jsonText"
import SplitText from "../split-text/split-text"

//
import styles from "./index.module.scss"

const MobileAnimationCard = ({
  image,
  subheading,
  description,
  active,
  activeHandler,
  changed,
  lastCard,
  index,
}) => {
  const cardRef = useRef()

  useEffect(() => {
    if (active) {
      changed(cardRef.current)
    }
  }, [active])

  return (
    <div
      className={`${styles.card} ${
        lastCard && active ? styles.activeLastCard : ""
      }`}
      onClick={() => activeHandler(index)}
      ref={cardRef}
    >
      <div className={`${styles.dot} ${active ? styles.active : ""}`} />
      <div className={styles.contentImage}>
        {image && <Img fluid={image.fluid} alt={image.description} />}
      </div>
      <div className={styles.contextText}>
        <div className={`${styles.subheading} ${active ? styles.active : ""}`}>
          {subheading && <h3>{subheading}</h3>}
        </div>
        {active && (
          <div className={styles.description}>
            {description &&
              (description.raw ? (
                <JsonText content={description} split={true} small={true} />
              ) : (
                <SplitText>{description}</SplitText>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default MobileAnimationCard
