// Core
import React, { useEffect, useState, useRef } from "react"

// InView detector

import { InView } from "react-intersection-observer"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-big-heading/split-big-heading"
import DriveUsCard from "./drive-us-card"

// Styles
import styles from "./drive-us.module.scss"

// Static Image
import Sample from "../../images/about/sample.png"

/*
 ** This block has both a minimum and maximum of three 'AccordionItem's
 */

const DriveUs = props => {
  const { sectionData } = props

  const heading = sectionData.ourValuesTitle

  console.log("ourValuesItems", sectionData?.ourValuesItems)

  let cardsList = []
  if (sectionData?.ourValuesItems.length % 2 === 0) {
    cardsList = [...Array(sectionData?.ourValuesItems.length / 2)].map(
      (elem, index) => ({
        firstCardTitle: sectionData?.ourValuesItems[index * 2]?.title,
        firstCardCopy: sectionData?.ourValuesItems[index * 2]?.description,
        secondCardTitle: sectionData?.ourValuesItems[index * 2 + 1]?.title,
        secondCardCopy: sectionData?.ourValuesItems[index * 2 + 1]?.description,
        image: {
          fluid: sectionData?.ourValuesItems[index * 2]?.image?.fluid,
          description:
            sectionData?.ourValuesItems[index * 2].image?.description,
        },
      })
    )
  }

  // const cardsList = [
  //   {
  //     firstCardTitle:
  //       "Think big and get the big picture right Think big and get the",
  //     firstCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     secondCardTitle: "Be bold",
  //     secondCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     image: {
  //       src: Sample,
  //       alt: "sample",
  //     },
  //   },
  //   {
  //     firstCardTitle: "Think big and get the big picture right",
  //     firstCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     secondCardTitle: "Be bold",
  //     secondCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     image: {
  //       src: Sample,
  //       alt: "sample",
  //     },
  //   },
  //   {
  //     firstCardTitle: "Think big and get the big picture right",
  //     firstCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     secondCardTitle: "Be bold",
  //     secondCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     image: {
  //       src: Sample,
  //       alt: "sample",
  //     },
  //   },
  //   {
  //     firstCardTitle: "Think big and get the big picture right",
  //     firstCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     secondCardTitle: "Be bold",
  //     secondCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     image: {
  //       src: Sample,
  //       alt: "sample",
  //     },
  //   },
  //   {
  //     firstCardTitle: "Think big and get the big picture right",
  //     firstCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     secondCardTitle: "Be bold",
  //     secondCardCopy:
  //       "A few game - changing ideas can compensate for numerous mistakes. Don't let the fear of failure or uncertainty stop you from pursuing ideas that could make a profound impact",
  //     image: {
  //       src: Sample,
  //       alt: "sample",
  //     },
  //   },
  // ]

  return (
    <ScrollSection>
      <section className={styles.driveUs}>
        <div className={styles.container}>
          <InView triggerOnce={true} threshold={0.2}>
            {({ inView, ref, entry }) => (
              <div ref={ref} inview={inView.toString()} className={styles.innerSection}>
                <div className={styles.headingContainer}>
                  <SplitHeading classString={styles.heading}>
                    {heading}
                  </SplitHeading>
                </div>
                <div>
                  {cardsList.map((card, index) => {
                    // <div>
                    //   <div>
                    //     <div
                    //       onMouseEnter={() => enableCopyHandler(index * 2)}
                    //       onMouseLeave={disableCopyHandler}
                    //     >
                    //       <div>0{index * 2 + 1}.</div>
                    //       <div> {contentList[index * 2].title}</div>
                    //     </div>
                    //     <div
                    //       onMouseEnter={() => enableCopyHandler(index * 2 + 1)}
                    //       onMouseLeave={disableCopyHandler}
                    //     >
                    //       <div>0{index * 2 + 2}.</div>
                    //       <div> {contentList[index * 2 + 1].title}</div>
                    //     </div>
                    //   </div>
                    //   {show && <div>{contentList[show].copy}</div>}
                    //   <div>
                    //     <img
                    //       src={contentList[index * 2].image.src}
                    //       alt={contentList[index * 2 + 1].image.alt}
                    //     />
                    //   </div>
                    // </div>

                    return (
                      <DriveUsCard
                        cardData={card}
                        numbering={index}
                        key={index}
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </ScrollSection>
  )
}

export default DriveUs
