// Core
import React, { useEffect, useState, useRef } from "react"

// InView detector

import { InView } from "react-intersection-observer"

// Components
import AccordionItem from "../accordion-item/accordion-item"
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-heading/split-heading"

// Styles
import styles from "./platform.module.scss"

/*
 ** This block has both a minimum and maximum of three 'AccordionItem's
 */

const Platform = props => {
  const { sectionData } = props

  const titles = {
    subHeading: sectionData?.accordionSubtitle,
    heading: sectionData?.accordionTitle,
  }

  const contentList = [
    {
      title: sectionData?.accordionItem1Title,
      copy: sectionData?.accordionItem1Copy?.accordionItem1Copy,
    },
    {
      title: sectionData?.accordionItem2Title,
      copy: sectionData?.accordionItem2Copy?.accordionItem2Copy,
    },
    {
      title: sectionData?.accordionItem3Title,
      copy: sectionData?.accordionItem3Copy?.accordionItem3Copy,
    },
  ]

  return (
    <ScrollSection>
      <section className={styles.platform}>
        <div className={styles.container}>
          <InView triggerOnce={true} threshold={0.2}>
            {({ inView, ref, entry }) => (
              <div
                ref={ref}
                inview={inView.toString()}
                data-scroll
                data-scroll-speed={1}
              >
                <div className={styles.title}>
                  {titles.subHeading && (
                    <SplitHeading classString={styles.subHeading}>
                      {titles.subHeading}
                    </SplitHeading>
                  )}
                  {titles.heading && (
                    <SplitHeading>{titles.heading}</SplitHeading>
                  )}
                </div>
                <div className={styles.content}>
                  {contentList.map((content, index) => (
                    <div className={styles.contentValue}>
                      <div>
                        <h3 className={styles.numbering}>0{index + 1}.</h3>
                      </div>
                      <div className={styles.rightContent}>
                        <div>
                          {content.title && (
                            <h3 className={styles.contentTitle}>
                              {content.title}
                            </h3>
                          )}
                        </div>
                        <div>
                          {content.copy && (
                            <p className={styles.copy}>{content.copy}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Platform
