// Core
import React, { useEffect, useRef, useState } from "react"
import Img from "gatsby-image"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import SplitHeading from "../split-heading/split-heading"

// Styles
import styles from "./drive-us.module.scss"

/*
 ** This block has both a minimum and maximum of three 'AccordionItem's
 */

const DriveUsCard = props => {
  const { cardData, numbering } = props
  const cardContentDetailContainerRef = useRef()

  const [active, setActive] = useState(false)

  const enableCopyHandler = value => {
    setActive(value)
  }

  const disableCopyHandler = () => {
    // cardContentDetailContainerRef.current.classList.add(styles.exit)
    // setTimeout(() => {
    //   setActive(false)
    // }, 701)

    setActive(false)
  }

  return (
    <InView triggerOnce={true} threshold={0.02}>
      {({ inView, ref, entry }) => (
        <div
        onMouseLeave={disableCopyHandler}
          ref={ref}
          inview={inView.toString()}
          className={`${styles.cardContainer} ${
            numbering % 2 !== 0 ? styles.rightAlign : ""
          }`}
        >
          <div
            // className={`${styles.cardContent} ${active ? styles.active : ""}`}
            className={`${styles.cardContent}`}
            onMouseLeave={active && disableCopyHandler}
            onClick={active && disableCopyHandler}
          >
            {!active ? (
              <div className={styles.cardInnerMain}>
                <div
                  className={styles.cardContentInner}
                  onMouseEnter={() => enableCopyHandler("first")}
                  onClick={() => enableCopyHandler("first")}
                >
                  <div>
                    <SplitHeading classString={styles.contentNumber}>
                      {numbering * 2 + 1 < 10
                        ? `0${numbering * 2 + 1}.`
                        : `${numbering * 2 + 1}.`}
                    </SplitHeading>
                  </div>
                  <div>
                    <h2 className={styles.contentHeading}>
                      {cardData.firstCardTitle}
                    </h2>
                  </div>
                </div>
                <div
                  className={styles.cardContentInner}
                  onMouseEnter={() => enableCopyHandler("second")}
                  onClick={() => enableCopyHandler("second")}
                >
                  <div>
                    <SplitHeading classString={styles.contentNumber}>
                      {numbering * 2 + 2 < 10
                        ? `0${numbering * 2 + 2}.`
                        : `${numbering * 2 + 2}.`}
                    </SplitHeading>
                  </div>
                  <div>
                    <h2 className={styles.contentHeading}>
                      {cardData.secondCardTitle}
                    </h2>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={styles.cardContentDetailWrapper}
                ref={cardContentDetailContainerRef}
              >
                <div className={styles.cardContentDetail}>
                  {/* <div>
              <SplitHeading classString={styles.contentNumber}>
                {active === "first"
                  ? numbering * 2 + 1 < 10
                    ? `0${numbering * 2 + 1}.`
                    : `${numbering * 2 + 1}.`
                  : numbering * 2 + 2 < 10
                  ? `0${numbering * 2 + 2}.`
                  : `${numbering * 2 + 2}.`}
              </SplitHeading>
            </div> */}
                  <div>
                    <h2 className={styles.contentHeading}>
                      {active === "first"
                        ? cardData.firstCardTitle
                        : cardData.secondCardTitle}
                    </h2>
                  </div>
                  <div>
                    <p className={styles.contentCopy}>
                      {active === "first"
                        ? cardData.firstCardCopy
                        : cardData.secondCardCopy}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.imageWrap}>
            {/* <img src={cardData.image.src} alt={cardData.image.alt} /> */}
            <Img
              fluid={cardData.image.fluid}
              alt={cardData.image.description}
            />
          </div>
        </div>
      )}
    </InView>
  )
}

export default DriveUsCard
