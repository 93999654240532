// Core
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react"

// InView detector
import { InView } from "react-intersection-observer"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import Animation from "../animation"
import SplitHeading from "../split-heading/split-heading"

// Mobile Detector
import { isMobile } from "react-device-detect"

// Styles
import styles from "./key-peices.module.scss"

// Context
import { ContextState } from "./../../context/Context"

// Static Image
import Sample from "../../images/about/key-peices.png"
import Sample2 from "../../images/about/key-peices2.png"
import MobileAnimation from "../mobile-animation"

// Gsap
// import gsap from "gsap"
// import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

/*
 ** This block has both a minimum and maximum of three 'AccordionItem's
 */

// Register plugins
// gsap.registerPlugin(ScrollTrigger)

const KeyPeices = props => {
  const { sectionData } = props

  const keyPeicesRef = useRef()
  const keyPeiceContainerRef = useRef()

  const [baseSize, setBaseSize] = useState(550)
  const [percentage, setPercentage] = useState(0)
  const [startAnimationDegree, setStartAnimationDegree] = useState(0)
  const storeState = useContext(ContextState)

  const heading = sectionData?.carouselTitle

  const nodes = [
    {
      // text: "Scientists",
      // description: "Ensuring solutions are grounded in real-world practice.",
      // align: "right",
      // image: {
      //   src: Sample,
      //   alt: "key-peices",
      // },
      ...sectionData?.carouselItem?.[0],
      rotation: 45,
      align: "right",
    },
    {
      ...sectionData?.carouselItem?.[1],
      rotation: 90,
      align: "right",
    },
    {
      ...sectionData?.carouselItem?.[2],
      rotation: 135,
      align: "right",
    },
    {
      ...sectionData?.carouselItem?.[3],
      rotation: 225,
      align: "left",
    },
    {
      ...sectionData?.carouselItem?.[4],
      rotation: 270,
      align: "left",
    },
    {
      ...sectionData?.carouselItem?.[5],
      rotation: 315,
      align: "left",
    },
  ]

  function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect()
    var elemTop = rect.top
    var elemBottom = rect.bottom

    // Only completely visible elements return true:
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible
  }

  const scrollOn = () => {
    if (window.scroll.on) {
      const { top: elemTop, height: elemHeight } = document
        .getElementById("keypeices-wrapper")
        .getBoundingClientRect()

      const innerSectionHeight = keyPeicesRef.current.getBoundingClientRect()
        .height
      let flag = true
      window.scroll.on("scroll", args => {
        if (document.getElementById("keypeices-wrapper")) {
          if (
            args.scroll.y >= elemTop &&
            args.scroll.y <= elemHeight + elemTop - innerSectionHeight
          ) {
            // if (startAnimationDegree <= 0) {
            //   setStartAnimationDegree(45)
            // }
            let newPercentage =
              ((args.scroll.y - elemTop) / (elemHeight - innerSectionHeight)) *
              100

            if (newPercentage >= 100) {
              newPercentage = 99.99
            } else if (newPercentage <= 0) {
              newPercentage = 0
              // setStartAnimationDegree(0)
            }
            if (flag) {
              // for (var i = 1; i <= 45; i++) setStartAnimationDegree(i)
              let i = 0
              let interval = setInterval(function () {
                setStartAnimationDegree(i)
                i++
                if (i > 45) {
                  clearInterval(interval) // stop the loop after 10 iterations
                }
              }, 10)
              // setStartAnimationDegree(45)
              flag = false
            }

            // if (Math.round(newPercentage) === 0 && !flag) {
            //   let i = 44
            //   let interval = setInterval(function () {
            //     setStartAnimationDegree(i)
            //     i--
            //     if (i < 0) {
            //       clearInterval(interval) // stop the loop after 10 iterations
            //     }
            //   }, 11)
            //   flag = true
            // }

            setPercentage(newPercentage)
          }
        }
      })
    }
  }

  const initializeResize = useCallback(() => {
    const containerElement = keyPeiceContainerRef.current

    const resizeAnimation = e => {
      if (!containerElement) return

      let { width } = containerElement.getBoundingClientRect()
      const screenHeight = window.innerHeight

      const maxWidth = screenHeight * 0.66

      if (width > maxWidth) {
        width = maxWidth
      }

      setBaseSize(width)
    }
    window.addEventListener("resize", resizeAnimation)

    resizeAnimation()
  }, [])
  // const scrollOff = () => {
  //   if (keyPeicesRef.current.scroll.off) {
  //     keyPeicesRef.current.scroll.off("scroll", args => {
  //       // if (mask.current)
  //       //   mask.current.style.transform =
  //       //     "scaleX(" + args.scroll.y / window.innerHeight + ")"
  //     })
  //   }
  // }

  useEffect(() => {
    if (!storeState.loader) {
      scrollOn()
      initializeResize()
    }
  }, [storeState.loader])

  return (
    <ScrollSection>
      <div className={styles.keyPeicesWrapper} id="keypeices-wrapper">
        <section
          ref={keyPeicesRef}
          data-scroll
          data-scroll-sticky
          data-scroll-target={`#keypeices-wrapper`}
          className={styles.keyPeices}
        >
          <div className={styles.container} ref={keyPeiceContainerRef}>
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div ref={ref} inview={inView.toString()}>
                  <div className={styles.title}>
                    {heading && <h3>{heading}</h3>}
                  </div>
                  <div className={styles.percentage}>
                    <Animation
                      baseSize={baseSize}
                      progress={percentage}
                      nodes={nodes}
                      startAnimationDegree={startAnimationDegree}
                    />
                  </div>
                  <div className={styles.mobileAnimation}>
                    <MobileAnimation
                      progress={percentage}
                      nodes={nodes}
                      sectionRef={keyPeicesRef}
                    />
                  </div>
                </div>
              )}
            </InView>
            {/* <Animation progress={50} /> */}
          </div>
        </section>
      </div>
    </ScrollSection>
  )
}

export default KeyPeices
