// Core
import React, { useState } from "react"
import Hammer from "react-hammerjs"
import Img from "gatsby-image"

// InView detector
import { InView } from "react-intersection-observer"

//Component
import SplitHeading from "./../split-big-heading/split-big-heading"
import SplitText from "./../split-text/split-text"
import VideoComponent from "./../video/video"

// Styles
import styles from "./modal.module.scss"

//SVG
import Circle from "./../../../static/images/svg/circle.inline.svg"
import QuarterCircle from "./../../../static/images/svg/quarter-circle.inline.svg"

import BtnArrow from "./../../../static/images/svg/small-next-arrow.inline.svg"
import BtnCircle from "./../../../static/images/svg/button-circle2.inline.svg"

// //Temp image
// import image from "./../../images/about/carousel-1.jpg"

const Modal = props => {
  const { setModalOpen } = props,
    { modalOpen } = props,
    { data } = props

  const contentList = data.biographyCarouselText

  let video = false

  if (data.biographyCarouselVideo) video = true

  const initialActive = 1,
    max = contentList.length

  let media

  const [currentActive, setCurrentActive] = useState(initialActive)

  const [isMax, setIsMax] = useState(false)

  if (video) {
    media = (
      <VideoComponent
        videoProps={data.biographyCarouselVideo}
        fluid={data.biographyCarouselImage.fluid}
        alt={data.biographyCarouselImage.description}
      />
    )
  } else {
    media = (
      <div className={styles.imagecontentwrap}>
        <Img
          fluid={data.biographyCarouselImage.fluid}
          alt={data.biographyCarouselImage.description}
        />
      </div>
    )
  }

  const handleSwipe = e => {
    if (e.direction === 4) {
      //left
      if (currentActive === 1) return
      setCurrentActive(currentActive - 1)
      setIsMax(false)
    }
    if (e.direction === 2) {
      //right
      if (currentActive === max) return
      else {
        setCurrentActive(currentActive + 1)
        if (currentActive === max - 1) {
          setIsMax(true)
        }
      }
    } else return
  }

  const clickPrev = e => {
    e.preventDefault()
    if (currentActive === 1) return
    setCurrentActive(currentActive - 1)
    setIsMax(false)
  }

  const clickNext = e => {
    e.preventDefault()
    if (currentActive === max) return
    else {
      setCurrentActive(currentActive + 1)
      if (currentActive === max - 1) {
        setIsMax(true)
      }
    }
  }
  const close = e => {
    e.preventDefault()
    setModalOpen(false)
  }

  return (
    <div
      className={styles.modal}
      data-active={currentActive}
      data-max={isMax}
      data-modal-open={modalOpen}
    >
      <div className={`container ${styles.modalcontainer}`}>
        <InView triggerOnce={true} threshold={0.2}>
          {({ inView, ref, entry }) => (
            <div className="cols" ref={ref} inview={inView.toString()}>
              <QuarterCircle />
              <button
                className={styles.closebutton}
                onClick={close}
                aria-label="Close Modal"
              >
                <Circle />
                <span></span>
                <span></span>
              </button>
              <div className={`col m12 t5 d5 ${styles.imagecol}`}>{media}</div>
              <div className={`col m12 tp10 t7 ${styles.textcol}`}>
                <SplitHeading>{`A Life Defined by Thinking Differently`}</SplitHeading>
                <Hammer onSwipe={handleSwipe}>
                  <div className={styles.textcontentwrap}>
                    <ul>
                      {contentList.map((card, index) => (
                        <li key={index} className={styles.textitem}>
                          <SplitText classString={`copy copy--s`}>
                            {card.itemCopy.itemCopy}
                          </SplitText>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Hammer>
                <div className={styles.marker}>
                  {contentList.map((card, index) => (
                    <span key={index}>
                      <span></span>
                    </span>
                  ))}
                </div>
                <div className={styles.buttons}>
                  <button
                    className={`${styles.button} ${styles.buttonprev}`}
                    onClick={clickPrev}
                    aria-label="Previous Item"
                  >
                    <span className={styles.circle}>
                      <BtnCircle />
                      <span className={styles.arrowWrap}>
                        <span className={styles.arrow}>
                          <BtnArrow />
                        </span>
                        <span className={styles.arrow}>
                          <BtnArrow />
                        </span>
                      </span>
                    </span>
                  </button>
                  <button
                    className={`${styles.button} ${styles.buttonnext}`}
                    onClick={clickNext}
                    aria-label="Next Item"
                  >
                    <span className={styles.circle}>
                      <BtnCircle />
                      <span className={styles.arrowWrap}>
                        <span className={styles.arrow}>
                          <BtnArrow />
                        </span>
                        <span className={styles.arrow}>
                          <BtnArrow />
                        </span>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </InView>
      </div>
    </div>
  )
}

export default Modal
