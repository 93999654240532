// Core
import React, { useState, useEffect, useRef } from "react"
import Hammer from "react-hammerjs"
import Img from "gatsby-image"

// InView detector
import { InView } from "react-intersection-observer"

//Component
import JsonText from "../_jsonText"
import ScrollSection from "./../locomotiveScroll/scrollSection"
import SplitHeading from "./../split-big-heading/split-big-heading"
import SplitText from "./../split-text/split-text"
import VideoComponent from "./../video/video"

// Styles
import styles from "./carousel.module.scss"

//SVG
// import Arrow from "./../../../static/images/svg/arrow.inline.svg"
import Arrow from "./../../../static/images/svg/small-next-arrow.inline.svg"
import Circle from "./../../../static/images/svg/button-circle2.inline.svg"

const Carousel = props => {
  const { bgColour } = props,
    { title } = props,
    { mainImg } = props,
    { vimeo } = props,
    { items } = props

  const itemContent = items.carouselItem,
    listRef = useRef()

  const initialActive = 1,
    max = itemContent.length

  //State
  const [currentActive, setCurrentActive] = useState(initialActive)
  const [isMax, setIsMax] = useState(false)

  useEffect(() => {
    const { current } = listRef,
      items = current.querySelectorAll("li")

    if (window.innerWidth < 1024) {
      current.style.maxHeight =
        items[currentActive - 1].scrollHeight + 50 + `px`
    } else {
      current.style.maxHeight = `100%`
    }
  }, [currentActive])

  const handleSwipe = e => {
    if (e.direction === 4) {
      //left
      if (currentActive === 1) return
      setCurrentActive(currentActive - 1)
      setIsMax(false)
    }
    if (e.direction === 2) {
      //right
      if (currentActive === max) return
      else {
        setCurrentActive(currentActive + 1)
        if (currentActive === max - 1) {
          setIsMax(true)
        }
      }
    } else return
  }

  const clickPrev = e => {
    e.preventDefault()
    if (currentActive === 1) return
    setCurrentActive(currentActive - 1)
    setIsMax(false)
  }

  const clickNext = e => {
    e.preventDefault()
    if (currentActive === max) return
    else {
      setCurrentActive(currentActive + 1)
      if (currentActive === max - 1) {
        setIsMax(true)
      }
    }
  }

  let imageCarousel = [],
    imgElement

  if (vimeo) {
    //   //return vimeo component
    imgElement = (
      <VideoComponent
        videoProps={vimeo}
        fluid={mainImg.fluid}
        alt={mainImg.description}
      />
    )
  } else if (itemContent[0].image) {
    for (let i = 0; i < itemContent.length; i++) {
      const element = itemContent[i]

      if (element.image) {
        imageCarousel.push(element.image)
      } else {
        imageCarousel.push(mainImg)
      }
    }

    imgElement = (
      <div className={styles.imageListWrap}>
        <ul className={styles.imageList}>
          {imageCarousel.map((item, key) => {
            return (
              <li key={key}>
                <Img fluid={item.fluid} alt={item.description} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  } else {
    imgElement = <Img fluid={mainImg.fluid} alt={mainImg.description} />
  }

  return (
    <ScrollSection>
      <div
        className={styles.carousel}
        data-active={currentActive}
        data-max={isMax}
        data-theme={bgColour}
        style={{ backgroundColor: `${bgColour}` }}
      >
        <div className={`container ${styles.carouselcontainer}`}>
          <InView triggerOnce={true} threshold={0.2}>
            {({ inView, ref, entry }) => (
              <div className="cols flex" ref={ref} inview={inView.toString()}>
                <div className={`col m12 t5 ${styles.imagecol}`} data-scroll>
                  <div className={styles.imagecontentwrap}>
                    <div className={styles.imagecontentwrapinner}>
                      <div className={styles.imagecontentwrapscale}>
                        {imgElement}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col m12 t7 ${styles.textcol}`}>
                  <div data-scroll data-scroll-speed={1}>
                    <SplitHeading classString={`${styles.heading}`}>
                      {title}
                    </SplitHeading>
                    <Hammer onSwipe={handleSwipe}>
                      <div className={styles.textcontentwrap}>
                        <ul ref={listRef}>
                          {itemContent.map((card, index) => (
                            <li key={index} className={styles.textitem}>
                              {card.subheading ? (
                                <SplitText
                                  classString={`subheading ${styles.subheading}`}
                                >
                                  {card.subheading}
                                </SplitText>
                              ) : (
                                ``
                              )}
                              <JsonText
                                content={card.text}
                                split={true}
                                small={true}
                                classString={`copy copy--s ${styles.copy}`}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Hammer>
                    <div className={styles.marker}>
                      {itemContent.map((card, index) => (
                        <span key={index}>
                          <span></span>
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={styles.buttons}>
                    <button
                      className={`${styles.button} ${styles.buttonprev}`}
                      onClick={clickPrev}
                      aria-label="Previous Item"
                    >
                      <span className={styles.circle}>
                        <Circle />
                        <span className={styles.arrowWrap}>
                          <span className={styles.arrow}>
                            <Arrow />
                          </span>
                          <span className={styles.arrow}>
                            <Arrow />
                          </span>
                        </span>
                      </span>
                    </button>
                    <button
                      className={`${styles.button} ${styles.buttonnext}`}
                      onClick={clickNext}
                      aria-label="Next Item"
                    >
                      <span className={styles.circle}>
                        <Circle />
                        <span className={styles.arrowWrap}>
                          <span className={styles.arrow}>
                            <Arrow />
                          </span>
                          <span className={styles.arrow}>
                            <Arrow />
                          </span>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </InView>
        </div>
      </div>
    </ScrollSection>
  )
}

export default Carousel
