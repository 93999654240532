// Core
import React from "react"
import Img from "gatsby-image"

// InView detector
import "intersection-observer"
import { InView } from "react-intersection-observer"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "../split-big-heading/split-big-heading"
import SplitText from "../split-text/split-text"
import VideoComponent from "../video/video"
import JsonText from "../_jsonText"

//SVG
import Circle from "./../../../static/images/svg/pxcircle.inline.svg"

// Styles
import styles from "./large-hero.module.scss"

// import Image from "../../images/strategy/strategy.jpg"

const Hero = props => {
  const { heroData, circle } = props

  console.log(heroData, "hero_data")

  let mediaContent, textContent, rightTextContent

  // let videoRef = useRef()

  let videoUrl = heroData.heroVideoVimeoCode

  if (videoUrl) {
    //   //return vimeo component
    mediaContent = (
      <VideoComponent
        videoProps={videoUrl}
        fluid={heroData.heroImage.fluid}
        alt={heroData.heroImage.description}
      />
    )
  } else {
    //return static image component

    // console.log("check==>", heroData.heroImage)

    mediaContent = (
      <div className={styles.imageWrap}>
        <Img
          fluid={heroData.heroImage.fluid}
          alt={heroData.heroImage.description}
        />
      </div>
    )
  }

  textContent = (
    <div className={styles.heading}>
      <SplitHeading h1={true} classString={`h2 ${styles.heading}`}>
        {heroData.heroTitle}
      </SplitHeading>
    </div>
  )

  if (heroData.heroText) {
    rightTextContent = (
      <div className={styles.textFocus} data-scroll>
        {heroData.heroText?.raw ? (
          <JsonText content={heroData.heroText} split={true} />
        ) : (
          <SplitText classString={`copy ${styles.copy}`}>
            {heroData.heroText}
          </SplitText>
        )}
      </div>
    )
  }

  return (
    <ScrollSection>
      <section className={`${styles.heroFocus}`} data-image-alignment={`right`}>
        <div className="container">
          <div className={`cols flex ${styles.cols}`}>
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div
                  className={`col m12 t10 ${styles.text}`}
                  data-scroll
                  data-scroll-speed={1}
                  ref={ref}
                  inview={inView.toString()}
                >
                  {textContent}
                </div>
              )}
            </InView>
            <InView triggerOnce={true} threshold={0.2}>
              {({ inView, ref, entry }) => (
                <div
                  className={`col m12 t9 ${styles.heromedia2}`}
                  data-scroll
                  ref={ref}
                  inview={inView.toString()}
                >
                  <div className={styles.heroImageTransform2}>
                    <div className={styles.heroImageInner}>
                      {circle && <Circle />}
                      {mediaContent}
                    </div>
                  </div>
                  {rightTextContent && (
                    <div
                      className={styles.description2}
                      data-scroll
                      data-scroll-speed={0.6}
                      data-scroll-delay={0.6}
                    >
                      {rightTextContent}
                    </div>
                  )}
                </div>
              )}
            </InView>
          </div>
        </div>
      </section>
    </ScrollSection>
  )
}

export default Hero
