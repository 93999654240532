import React, { useEffect, useMemo, useRef, useState } from "react"
import { useCallback } from "react"

//
import styles from "./index.module.scss"
import MobileAnimationCard from "./mobile-animation-card"

const MobileAnimation = ({ progress = 0, nodes, sectionRef }) => {
  console.log("nodes", nodes)
  const [active, setActive] = useState(0)
  // const [fixed, setFixed] = useState(false)
  // const mobileAnimationWrapperRef = useRef()
  const mobileAnimationContainerRef = useRef()

  const activeHandler = index => {
    setActive(index)
  }

  const changed = elem => {
    mobileAnimationContainerRef.current.style.setProperty(
      "--active-height",
      active > 0 ? elem.offsetTop + "px" : "0px"
    )
  }

  // let flag = false

  // const scrolling = elemTop => {
  //   const { top } = mobileAnimationWrapperRef.current.getBoundingClientRect()
  //   const { bottom } = sectionRef.current.getBoundingClientRect()
  //   // console.log("___check", bottom - window.innerHeight <= 0, fixed)
  //   if (top <= 0 && !flag) {
  //     // apply fixed
  //     setFixed(true)
  //     flag = true
  //     console.log("___check", bottom - window.innerHeight <= 0 && flag)
  //   } else if (bottom - window.innerHeight <= 0 && flag) {
  //     // remove fixed
  //     setFixed(false)
  //     flag = false
  //   }
  //   // }
  // }

  // useEffect(() => {
  //   if (fixed) {
  //     // apply fixed
  //     mobileAnimationContainerRef.current.style.position = "fixed"
  //     mobileAnimationContainerRef.current.style.top = "76px"
  //   } else {
  //     // remove fixed
  //     mobileAnimationContainerRef.current.style.position = "relative"
  //     mobileAnimationContainerRef.current.style.top = "0px"
  //   }
  // }, [fixed])

  // useEffect(() => {
  //   const {
  //     top: elemTop,
  //   } = mobileAnimationWrapperRef.current.getBoundingClientRect()
  //   window.addEventListener("scroll", () => scrolling(elemTop))
  //   return () => window.removeEventListener("scroll", scrolling)
  // }, [])

  return (
    <div
      className={styles.mobileAnimationWrapper}
      // ref={mobileAnimationWrapperRef}
    >
      <div
        className={styles.mobileAnimationContainer}
        ref={mobileAnimationContainerRef}
      >
        {nodes.map((node, index) => (
          <MobileAnimationCard
            key={index}
            image={node.image}
            subheading={node.subheading}
            description={node.text}
            active={active === index}
            activeHandler={activeHandler}
            changed={changed}
            lastCard={index === nodes.length - 1}
            index={index}
          />
        ))}
      </div>
    </div>
  )
}

export default MobileAnimation
